import React from "react"
import { Link } from 'gatsby'
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from '../components/Layout'
import CTAStart from "../components/CTAStart"
import SEO from "../components/SEO"

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

const Results = (props) => {
    return (
        <Layout>
            <SEO title={"Results"} />
            <div className="rbs-section">
                <div className="rbs-box-a">
                    <div className="content">
                        <h1 className="title-xl mb-8">
                            Results
                    </h1>
                    </div>
                </div>
                <div className="rbs-bg bg-04"></div>
            </div>

            <div id="brand-strategy" className="rbs-section">
                <div className="rbs-bg bg-04"></div>
                <div className="rbs-box-a">
                    <div className="content">
                        <h2 className="title-l mb-16">
                            Brand Strategy
                        </h2>
                        <div className="grid-a">
                            <div>
                                <h3 className="title-s-b ">Brand Concept</h3>
                                <p className="body-l text-rbs-black-50">The core concept and positioning of your brand.</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Design Principles</h3>
                                <p className="body-l text-rbs-black-50">The guiding principles for various brand applications.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="visual-identity" className="rbs-section">
                <div className="rbs-box-a">
                    <div className="content">
                        <h2 className="title-l mb-16">
                            Visual
                            Identity
                        </h2>
                        <div className="grid-a">
                            <div>
                                <h3 className="title-s-b">Logotype</h3>
                                <p className="body-l text-rbs-black-50">Logotype is the most important element of your corporate identity.</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Typography</h3>
                                <p className="body-l text-rbs-black-50">Proper typographic guidelines are essential to the visual identity and ensure uniform communication style accross touchpoints.</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Colour</h3>
                                <p className="body-l text-rbs-black-50">Your brand’s colour pallette is one of the most powerful and recognisable assets of your visual identity.</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Imagery</h3>
                                <p className="body-l text-rbs-black-50">The power of visual imagery can articulate your brand qualities in a distinct and unified way.</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Elements</h3>
                                <p className="body-l text-rbs-black-50">Iconography, infographics, patterns and other supporting elements that carry your brand DNA.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rbs-bg bg-04"></div>

            </div>

            <div id="brand-applications" className="rbs-section">
                <div className="rbs-bg bg-04"></div>
                <div className="rbs-box-a">
                    <div className="content">
                        <h2 className="title-l mb-16">
                            Brand<br />
                            Applications
                        </h2>
                        <div className="grid-a">
                            <div>
                                <h3 className="title-s-b">Print Collateral</h3>
                                <p className="body-l text-rbs-black-50 mb-2">
                                    Business card<br />
                                    Poster<br />
                                    Roll up
                                </p>
                                <p className="body text-rbs-black-50">
                                    And more …
                                </p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Social Media</h3>
                                <p className="body-l text-rbs-black-50 mb-2">
                                    Avatars<br />
                                    Cover image<br />
                                    Post template<br />
                                    Story template
                                </p>
                                <p className="body text-rbs-black-50">
                                    And more …
                                </p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Templates</h3>
                                <p className="body-l text-rbs-black-50 mb-2">
                                    Document<br />
                                    Newsletter<br />
                                    Presentation<br />
                                    Email footer
                                </p>
                                <p className="body text-rbs-black-50">
                                    And more …
                                </p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Branded Items</h3>
                                <p className="body-l text-rbs-black-50 mb-2">
                                    Notebook<br />
                                    Pen<br />
                                    Totebag<br />
                                    Box<br />
                                    Phone case<br />
                                </p>
                                <p className="body text-rbs-black-50">
                                    And more …
                                </p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Digital Channels</h3>
                                <p className="body-l text-rbs-black-50 mb-2">
                                    Landingpage design<br />
                                    App design
                                </p>
                                <p className="body text-rbs-black-50">
                                    And more …
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="case-bg-05 p-16 pt-32 md:p-32">
                <div className="text-white case-grid">
                    <div>
                        <h3 className="title">Callista</h3>
                        <p className="body-xl opacity-50">Outstanding Business Software</p>
                    </div>
                    <div className="col-span-2">
                        <p className="body-l opacity-50">Callista is a one stop shop for technical and business development needs. We helped them quickly refresh their brand through the RBS process. Utilising their updated brand helped Callista significantly grow their quarter on quarter earnings only shortly after applying the new designs.</p>
                    </div>
                </div>
                <div className="relative w-full mx-auto">
                    <Img imgStyle={{ objectFit: 'contain' }} fluid={props.data.case05.childImageSharp.fluid} />
                </div>

                <div className="text-center mt-16">
                    <Link to="/cases" className="text-white opacity-50 body-l link-arrow">View more cases</Link>
                </div>

            </div>

            <CTAStart />
        </Layout>
    )
}

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    case05: file(relativePath: { eq: "case-05.png" }) {
      ...fluidImage
    }
  }
`

export default Results;